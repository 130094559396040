.navBar {
  font-family: "Montserrat", sans-serif;
  background-color: #f0e1ded0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.navBarShadow {
  background-color: #f0e1ded0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.navbar-nav {
  text-align: center;
}

.navBarNoShadow {
  background-color: #f0e1ded0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.menuContainer {
  padding-bottom: 15px;
}

.logo {
  cursor: pointer;
}

.navBarBrand {
  font-family: "Libre Baskerville", serif;
  color: #bf7373;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.1em;
  font-size: 19px;
}

#navBarToggler {
  min-width: initial;
}

.menuContainer {
  gap: 20px;
}

.navLinksContainer {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.navLinks:hover {
  color: #bf7373;
  transition: all 0.5s;
}

/* nav button effects */
a {
  text-decoration: none;
  color: inherit;
}

.navItem {
  position: relative;
  cursor: pointer;
}

.navItem,
.navItem:after {
  transition: all 0.5s;
}
.navItem:hover {
  color: #bf7373;
  font-weight: bold;
}

.navItem:hover:after {
  width: 100%;
}

.activeMenu {
  text-decoration: none;
  font-weight: bold;
  color: #bf7373;
}

.navBarBrand:hover {
  transition: all 0.5s;
  color: #a66363;
}
