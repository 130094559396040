.aboutContainer {
  background-color: #f0e1de;
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0 5rem 0;
}

.aboutContentsContainer {
  display: flex;
  gap: 5vw;
  width: 70vw;
}

.aboutLeftContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutLeftContents {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.aboutRightContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutRightContents {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.aboutButtonContainer {
  display: flex;
  column-gap: 3vw;
}

.aboutHeading {
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
  font-size: 3.5rem;
}

.aboutSubheading {
  font-size: 1.5rem;
}

.langIcon {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.langIconsContainer {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  justify-content: flex-start;
  align-items: center;
}

.langTextContainer {
  display: flex;
  flex-direction: column;
}

span {
  font-weight: 600;
}
