.footer {
  background-color: #f0e1de;
  font-family: "Montserrat", sans-serif;
  height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerContainer {
  display: flex;
}

.footerCopyright {
  font-family: "Libre Baskerville", serif;
  color: #bf7373;
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;
}

.footerCopyright:hover {
  transition: all 0.5s;
  color: #a66363;
}

.navLinksContainer {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.navLinks:hover {
  color: #bf7373;
  transition: all 0.5s;
}
