.heroContainer {
  background-color: #f0e1de;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.heroMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5vw;
  gap: 5vw;
  width: 70vw;
}

.heroLeftContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroRightContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  order: -1;
}

.heroLeftContents {
  display: flex;
  flex-direction: column;
  grid-gap: 3vh;
  gap: 3vh;
  align-items: center;
  justify-content: center;
}

.heroButtonContainer {
  display: flex;
  grid-column-gap: 3vw;
  -webkit-column-gap: 3vw;
          column-gap: 3vw;
}

.heroScrollContainer {
  position: absolute;
  bottom: 5vh;
}

.profilePicture {
  width: 25vw;
  min-width: 200px;
}

.heroHeading {
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
  font-size: 3.5rem;
}

.heroSubheading {
  font-size: 1.5rem;
}

/* scroll down button */
.scrollButton {
  position: relative;
  top: 12vh;
}

.scrollButton p {
  font-weight: normal 400 20px/1;
  letter-spacing: 0.1em;
  font-size: 1.2rem;
  margin: 0 auto;
}

.scrollButton .arrowContainer {
  position: relative;
  height: 70px;
}

.scrollButton a:hover {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.scrollButton a .arrowContainer span {
  position: absolute;
  left: 50%;
  top: 0;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: arrow 2s infinite;
  animation: arrow 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
.scrollButton a .arrowContainer span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.scrollButton a .arrowContainer span:nth-of-type(2) {
  top: 16px;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
.scrollButton a .arrowContainer span:nth-of-type(3) {
  top: 32px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
@-webkit-keyframes arrow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes arrow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.typing {
  width: 100%;
  -webkit-animation: typing 3s steps(40), blink 0.5s step-end infinite alternate;
          animation: typing 3s steps(40), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
}

@-webkit-keyframes typing {
  from {
    width: 0;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
}

@-webkit-keyframes blink {
  50% {
    border-color: transparent;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.aboutContainer {
  background-color: #f0e1de;
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0 5rem 0;
}

.aboutContentsContainer {
  display: flex;
  grid-gap: 5vw;
  gap: 5vw;
  width: 70vw;
}

.aboutLeftContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutLeftContents {
  display: flex;
  flex-direction: column;
  grid-gap: 3vh;
  gap: 3vh;
}

.aboutRightContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutRightContents {
  display: flex;
  flex-direction: column;
  grid-gap: 3vh;
  gap: 3vh;
}

.aboutButtonContainer {
  display: flex;
  grid-column-gap: 3vw;
  -webkit-column-gap: 3vw;
          column-gap: 3vw;
}

.aboutHeading {
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
  font-size: 3.5rem;
}

.aboutSubheading {
  font-size: 1.5rem;
}

.langIcon {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.langIconsContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 3vh;
  gap: 3vh;
  justify-content: flex-start;
  align-items: center;
}

.langTextContainer {
  display: flex;
  flex-direction: column;
}

span {
  font-weight: 600;
}

.workContainer {
  background-color: #f0e1de;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 4rem;
  row-gap: 4rem;
  padding: 5rem 0 5rem 0;
  min-height: 100vh;
}

.singleWorkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.workMainContainer {
  display: flex;
  grid-gap: 3vw;
  gap: 3vw;
  grid-row-gap: 3vh;
  row-gap: 3vh;
  width: 70vw;
}

.workLeftContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.workRightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.workPicture {
  width: 70vw;
  border-radius: 20px;
}

.workHeading {
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
  font-size: 3.5rem;
}

.workSubheading {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
}

.workButtonContainer {
  display: flex;
  grid-column-gap: 3vw;
  -webkit-column-gap: 3vw;
          column-gap: 3vw;
}

#dropDownButton {
  position: absolute;
  z-index: 100;
  background-color: red;
}

.btn-custom {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #bf7373;
  border-color: #bf7373;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: hidden;
  min-width: 120px;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.workSubheading:hover {
  color: #bf7373;
  transition: all 0.5s;
}

html {
  font-size: calc(11px + 0.390625vw);
  background-color: #f0e1de;
}

button {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #bf7373;
  border-color: #bf7373;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: hidden;
  min-width: 120px;
}

/* button:hover {
  color: #fff;
  background-color: #a66363;
  border-color: #a66363;
} */

button:focus {
  color: #fff;
  background-color: #a66363;
  border-color: #a66363;
}

/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 5 breakpoints
*/

/* prevent sticky hover effects for buttons on touch devices */
@media (hover: hover) {
  button:hover {
    color: #fff;
    background-color: #a66363;
    border-color: #a66363;
  }
}

/* 
Extra small devices (portrait phones, less than 576px) 
No media query since this is the default in Bootstrap because it is "mobile first"
*/

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .langIcon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .langIcon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .zoom {
    width: 70vw;
    border-radius: 10px;
    overflow: hidden;
  }

  .zoom img {
    width: 100%;
    transition: 0.5s all ease-in-out;
  }

  .zoom:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .heroLeftContainer > .heroLeftContents {
    align-items: flex-start;
    min-width: 400px;
  }

  .langIcon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .zoom {
    width: 70vw;
    border-radius: 10px;
    overflow: hidden;
  }

  .zoom img {
    width: 100%;
    transition: 0.5s all ease-in-out;
  }

  .zoom:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .navBar {
    height: 56px;
  }

  .fixed-top {
    width: 100vw;
  }

  .navBarShadow {
    background-color: #f0e1ded0;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }

  .menuContainer {
    background-color: transparent !important;
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
    box-shadow: none !important;
    padding-bottom: 0 !important;
  }

  .navItem:after {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: "";
    color: transparent;
    background: #bf7373;
    height: 3px;
  }

  .navLinksContainer {
    margin-left: 30px;
  }
}

/* for hero page order */
@media (min-width: 1116px) {
  .heroLeftContainer > .heroLeftContents {
    align-items: flex-start;
    min-width: 400px;
  }

  .heroRightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    order: 1;
  }

  .langIcon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .zoom {
    width: 70vw;
    border-radius: 10px;
    overflow: hidden;
  }

  .zoom img {
    width: 100%;
    transition: 0.5s all ease-in-out;
  }

  .zoom:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .navBar {
    height: 56px;
  }

  .navBarShadow {
    background-color: #f0e1ded0;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }

  .menuContainer {
    background-color: transparent !important;
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
    box-shadow: none !important;
    padding-bottom: 0 !important;
  }
  .navItem:after {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: "";
    color: transparent;
    background: #bf7373;
    height: 3px;
  }

  .navLinksContainer {
    margin-left: 30px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .heroLeftContainer > .heroLeftContents {
    align-items: flex-start;
    min-width: 400px;
  }

  .heroRightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    order: 1;
  }

  .langIcon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .zoom {
    width: 40vw;
    border-radius: 10px;
    overflow: hidden;
  }

  .zoom img {
    width: 100%;
    transition: 0.5s all ease-in-out;
  }

  .zoom:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .toTheTopButton {
    position: relative;
    top: 20vh !important;
  }

  .navBar {
    height: 56px;
  }

  .navBarShadow {
    background-color: #f0e1ded0;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }

  .menuContainer {
    background-color: transparent !important;
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
    box-shadow: none !important;
    padding-bottom: 0 !important;
  }

  .navItem:after {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: "";
    color: transparent;
    background: #bf7373;
    height: 3px;
  }

  .navLinksContainer {
    margin-left: 30px;
  }
}

/* Extra extra large devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {
  .heroLeftContainer > .heroLeftContents {
    align-items: flex-start;
    min-width: 420px;
  }

  .heroRightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .langIcon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .zoom {
    width: 40vw;
    border-radius: 10px;
    overflow: hidden;
  }

  .zoom img {
    width: 100%;
    transition: 0.5s all ease-in-out;
  }

  .zoom:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .toTheTopButton {
    position: relative;
    top: 20vh !important;
  }

  .navBar {
    height: 56px;
  }

  .navBarShadow {
    background-color: #f0e1ded0;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }

  .menuContainer {
    background-color: transparent !important;
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
    box-shadow: none !important;
    padding-bottom: 0 !important;
  }

  .navItem:after {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: "";
    color: transparent;
    background: #bf7373;
    height: 3px;
  }

  .navLinksContainer {
    margin-left: 30px;
  }
}

.contactsContainer {
  background-color: #f0e1de;
  font-family: "Libre Baskerville", serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0 5rem 0;
  min-height: 100vh;
}

.contactsMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5vw;
  gap: 5vw;
  width: 70vw;
}

.contactsLeftContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactsRightContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactsLeftContents {
  display: flex;
  flex-direction: column;
  grid-gap: 2vh;
  gap: 2vh;
}

.contactsElementsContainer {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  grid-gap: 2vh;
  gap: 2vh;
}

.contactsElements {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}

.contactsElements:hover {
  color: #bf7373;
  transition: all 0.5s;
}

.contactsElements p {
  margin: 0;
  padding: 4px 0 0 0;
  font-family: "Montserrat", sans-serif;
}

.formContainer {
  font-family: "Montserrat", sans-serif;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.formGroup {
  border: none;
  margin-bottom: 1.5rem;
}

input,
textarea {
  border: 0;
  outline: 0;
  border-bottom: 1px solid black;
  background: transparent;
}

.formLabels {
  display: inline-block;
  font-weight: 500;
}

.message {
  height: 5rem;
}

.contactsHeading {
  font-weight: bold;
  font-size: 3.5rem;
}

.contactsSubheading {
  font-family: "Montserrat", sans-serif;
}

.submitButton {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-self: center;
}

.modal-content {
  font-family: "Montserrat", sans-serif;
}

.modal-header {
  color: #bf7373;
}

.checkIcon {
  margin-right: 5px;
}

.modal-header .btn-close {
  min-width: initial;
}

/* scroll up button */
.toTheTopButton {
  position: relative;
  top: 7vh;
}

.toTheTopButton p {
  font-family: "Montserrat", sans-serif;
  font-weight: normal 400 20px/1;
  letter-spacing: 0.1em;
  font-size: 1.2rem;
  margin: 0 auto;
}

.toTheTopButton .arrowContainer {
  position: relative;
  height: 70px;
}

.toTheTopButton a:hover {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.toTheTopButton a .arrowContainer span {
  position: absolute;
  left: 50%;
  top: 0;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-animation: arrow 2s infinite;
  animation: arrow 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
.toTheTopButton a .arrowContainer span:nth-of-type(1) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.toTheTopButton a .arrowContainer span:nth-of-type(2) {
  top: 16px;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
.toTheTopButton a .arrowContainer span:nth-of-type(3) {
  top: 32px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes arrow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes arrow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.navBar {
  font-family: "Montserrat", sans-serif;
  background-color: #f0e1ded0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.navBarShadow {
  background-color: #f0e1ded0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.navbar-nav {
  text-align: center;
}

.navBarNoShadow {
  background-color: #f0e1ded0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.menuContainer {
  padding-bottom: 15px;
}

.logo {
  cursor: pointer;
}

.navBarBrand {
  font-family: "Libre Baskerville", serif;
  color: #bf7373;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.1em;
  font-size: 19px;
}

#navBarToggler {
  min-width: initial;
}

.menuContainer {
  grid-gap: 20px;
  gap: 20px;
}

.navLinksContainer {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.navLinks:hover {
  color: #bf7373;
  transition: all 0.5s;
}

/* nav button effects */
a {
  text-decoration: none;
  color: inherit;
}

.navItem {
  position: relative;
  cursor: pointer;
}

.navItem,
.navItem:after {
  transition: all 0.5s;
}
.navItem:hover {
  color: #bf7373;
  font-weight: bold;
}

.navItem:hover:after {
  width: 100%;
}

.activeMenu {
  text-decoration: none;
  font-weight: bold;
  color: #bf7373;
}

.navBarBrand:hover {
  transition: all 0.5s;
  color: #a66363;
}

.footer {
  background-color: #f0e1de;
  font-family: "Montserrat", sans-serif;
  height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerContainer {
  display: flex;
}

.footerCopyright {
  font-family: "Libre Baskerville", serif;
  color: #bf7373;
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;
}

.footerCopyright:hover {
  transition: all 0.5s;
  color: #a66363;
}

.navLinksContainer {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.navLinks:hover {
  color: #bf7373;
  transition: all 0.5s;
}

