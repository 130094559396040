.heroContainer {
  background-color: #f0e1de;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.heroMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
  width: 70vw;
}

.heroLeftContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroRightContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  order: -1;
}

.heroLeftContents {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  align-items: center;
  justify-content: center;
}

.heroButtonContainer {
  display: flex;
  column-gap: 3vw;
}

.heroScrollContainer {
  position: absolute;
  bottom: 5vh;
}

.profilePicture {
  width: 25vw;
  min-width: 200px;
}

.heroHeading {
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
  font-size: 3.5rem;
}

.heroSubheading {
  font-size: 1.5rem;
}

/* scroll down button */
.scrollButton {
  position: relative;
  top: 12vh;
}

.scrollButton p {
  font-weight: normal 400 20px/1;
  letter-spacing: 0.1em;
  font-size: 1.2rem;
  margin: 0 auto;
}

.scrollButton .arrowContainer {
  position: relative;
  height: 70px;
}

.scrollButton a:hover {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.scrollButton a .arrowContainer span {
  position: absolute;
  left: 50%;
  top: 0;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: arrow 2s infinite;
  animation: arrow 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
.scrollButton a .arrowContainer span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.scrollButton a .arrowContainer span:nth-of-type(2) {
  top: 16px;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
.scrollButton a .arrowContainer span:nth-of-type(3) {
  top: 32px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
@-webkit-keyframes arrow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes arrow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.typing {
  width: 100%;
  animation: typing 3s steps(40), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
