html {
  font-size: calc(11px + 0.390625vw);
  background-color: #f0e1de;
}

button {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #bf7373;
  border-color: #bf7373;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: hidden;
  min-width: 120px;
}

/* button:hover {
  color: #fff;
  background-color: #a66363;
  border-color: #a66363;
} */

button:focus {
  color: #fff;
  background-color: #a66363;
  border-color: #a66363;
}

/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 5 breakpoints
*/

/* prevent sticky hover effects for buttons on touch devices */
@media (hover: hover) {
  button:hover {
    color: #fff;
    background-color: #a66363;
    border-color: #a66363;
  }
}

/* 
Extra small devices (portrait phones, less than 576px) 
No media query since this is the default in Bootstrap because it is "mobile first"
*/

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .langIcon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .langIcon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .zoom {
    width: 70vw;
    border-radius: 10px;
    overflow: hidden;
  }

  .zoom img {
    width: 100%;
    transition: 0.5s all ease-in-out;
  }

  .zoom:hover img {
    transform: scale(1.1);
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .heroLeftContainer > .heroLeftContents {
    align-items: flex-start;
    min-width: 400px;
  }

  .langIcon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .zoom {
    width: 70vw;
    border-radius: 10px;
    overflow: hidden;
  }

  .zoom img {
    width: 100%;
    transition: 0.5s all ease-in-out;
  }

  .zoom:hover img {
    transform: scale(1.1);
  }

  .navBar {
    height: 56px;
  }

  .fixed-top {
    width: 100vw;
  }

  .navBarShadow {
    background-color: #f0e1ded0;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }

  .menuContainer {
    background-color: transparent !important;
    backdrop-filter: none !important;
    box-shadow: none !important;
    padding-bottom: 0 !important;
  }

  .navItem:after {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: "";
    color: transparent;
    background: #bf7373;
    height: 3px;
  }

  .navLinksContainer {
    margin-left: 30px;
  }
}

/* for hero page order */
@media (min-width: 1116px) {
  .heroLeftContainer > .heroLeftContents {
    align-items: flex-start;
    min-width: 400px;
  }

  .heroRightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    order: 1;
  }

  .langIcon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .zoom {
    width: 70vw;
    border-radius: 10px;
    overflow: hidden;
  }

  .zoom img {
    width: 100%;
    transition: 0.5s all ease-in-out;
  }

  .zoom:hover img {
    transform: scale(1.1);
  }

  .navBar {
    height: 56px;
  }

  .navBarShadow {
    background-color: #f0e1ded0;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }

  .menuContainer {
    background-color: transparent !important;
    backdrop-filter: none !important;
    box-shadow: none !important;
    padding-bottom: 0 !important;
  }
  .navItem:after {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: "";
    color: transparent;
    background: #bf7373;
    height: 3px;
  }

  .navLinksContainer {
    margin-left: 30px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .heroLeftContainer > .heroLeftContents {
    align-items: flex-start;
    min-width: 400px;
  }

  .heroRightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    order: 1;
  }

  .langIcon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .zoom {
    width: 40vw;
    border-radius: 10px;
    overflow: hidden;
  }

  .zoom img {
    width: 100%;
    transition: 0.5s all ease-in-out;
  }

  .zoom:hover img {
    transform: scale(1.1);
  }

  .toTheTopButton {
    position: relative;
    top: 20vh !important;
  }

  .navBar {
    height: 56px;
  }

  .navBarShadow {
    background-color: #f0e1ded0;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }

  .menuContainer {
    background-color: transparent !important;
    backdrop-filter: none !important;
    box-shadow: none !important;
    padding-bottom: 0 !important;
  }

  .navItem:after {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: "";
    color: transparent;
    background: #bf7373;
    height: 3px;
  }

  .navLinksContainer {
    margin-left: 30px;
  }
}

/* Extra extra large devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {
  .heroLeftContainer > .heroLeftContents {
    align-items: flex-start;
    min-width: 420px;
  }

  .heroRightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .langIcon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .zoom {
    width: 40vw;
    border-radius: 10px;
    overflow: hidden;
  }

  .zoom img {
    width: 100%;
    transition: 0.5s all ease-in-out;
  }

  .zoom:hover img {
    transform: scale(1.1);
  }

  .toTheTopButton {
    position: relative;
    top: 20vh !important;
  }

  .navBar {
    height: 56px;
  }

  .navBarShadow {
    background-color: #f0e1ded0;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }

  .menuContainer {
    background-color: transparent !important;
    backdrop-filter: none !important;
    box-shadow: none !important;
    padding-bottom: 0 !important;
  }

  .navItem:after {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: "";
    color: transparent;
    background: #bf7373;
    height: 3px;
  }

  .navLinksContainer {
    margin-left: 30px;
  }
}
