.workContainer {
  background-color: #f0e1de;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4rem;
  padding: 5rem 0 5rem 0;
  min-height: 100vh;
}

.singleWorkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.workMainContainer {
  display: flex;
  gap: 3vw;
  row-gap: 3vh;
  width: 70vw;
}

.workLeftContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.workRightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
}

.workPicture {
  width: 70vw;
  border-radius: 20px;
}

.workHeading {
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
  font-size: 3.5rem;
}

.workSubheading {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
}

.workButtonContainer {
  display: flex;
  column-gap: 3vw;
}

#dropDownButton {
  position: absolute;
  z-index: 100;
  background-color: red;
}

.btn-custom {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #bf7373;
  border-color: #bf7373;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: hidden;
  min-width: 120px;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.workSubheading:hover {
  color: #bf7373;
  transition: all 0.5s;
}
