.contactsContainer {
  background-color: #f0e1de;
  font-family: "Libre Baskerville", serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0 5rem 0;
  min-height: 100vh;
}

.contactsMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
  width: 70vw;
}

.contactsLeftContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactsRightContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactsLeftContents {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.contactsElementsContainer {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.contactsElements {
  display: flex;
  gap: 30px;
}

.contactsElements:hover {
  color: #bf7373;
  transition: all 0.5s;
}

.contactsElements p {
  margin: 0;
  padding: 4px 0 0 0;
  font-family: "Montserrat", sans-serif;
}

.formContainer {
  font-family: "Montserrat", sans-serif;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.formGroup {
  border: none;
  margin-bottom: 1.5rem;
}

input,
textarea {
  border: 0;
  outline: 0;
  border-bottom: 1px solid black;
  background: transparent;
}

.formLabels {
  display: inline-block;
  font-weight: 500;
}

.message {
  height: 5rem;
}

.contactsHeading {
  font-weight: bold;
  font-size: 3.5rem;
}

.contactsSubheading {
  font-family: "Montserrat", sans-serif;
}

.submitButton {
  width: fit-content;
  align-self: center;
}

.modal-content {
  font-family: "Montserrat", sans-serif;
}

.modal-header {
  color: #bf7373;
}

.checkIcon {
  margin-right: 5px;
}

.modal-header .btn-close {
  min-width: initial;
}

/* scroll up button */
.toTheTopButton {
  position: relative;
  top: 7vh;
}

.toTheTopButton p {
  font-family: "Montserrat", sans-serif;
  font-weight: normal 400 20px/1;
  letter-spacing: 0.1em;
  font-size: 1.2rem;
  margin: 0 auto;
}

.toTheTopButton .arrowContainer {
  position: relative;
  height: 70px;
}

.toTheTopButton a:hover {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.toTheTopButton a .arrowContainer span {
  position: absolute;
  left: 50%;
  top: 0;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-animation: arrow 2s infinite;
  animation: arrow 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
.toTheTopButton a .arrowContainer span:nth-of-type(1) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.toTheTopButton a .arrowContainer span:nth-of-type(2) {
  top: 16px;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
.toTheTopButton a .arrowContainer span:nth-of-type(3) {
  top: 32px;
  animation-delay: 0s;
}
@-webkit-keyframes arrow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes arrow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
